// Copyright © 2022, 2023, 2024 Artha Astra Inc.
// All rights reserved.

import React from 'react';
import images from "../../utils/images";
import TrackEvent from '../../utils/events';

const Introduction = () => {
    return (
        <>
            {/* Web Version */}
            <div
                className="hidden xl:flex relative w-full aspect-[16/9] bg-no-repeat bg-center bg-cover overflow-visible mt-[3%]"
                style={{ backgroundImage: `url(${images.introBg})` }}
            >
                {/* Text Section */}
                <div className="hidden lg:flex flex-col absolute left-[13%] top-[10%] w-[829px] h-[102px] text-center lg:text-left">
                    <h1 className="font-lora font-bold text-[40px] text-[#0E193E]">
                        Tools and co-pilot for preserving
                        <br />
                        <span className="text-[#0E193E]">and growing your </span>
                        <span className="text-[#4565D4] italic">money!</span>
                    </h1>

                    <div className='flex flex-row space-x-6 mt-6'>
                        <a href='https://play.google.com/store/apps/details?id=com.finurja.finurja_mobile&hl=en_IN' target="_blank" rel="noopener noreferrer" onClick={() => TrackEvent('DownloadAppClicked')}>
                            <div className="inline-flex justify-between items-center space-x-4  py-2 px-4 bg-[#2B4CBA] rounded-[8px] mt-[5%] border-[#213A8F] border-[1.76px]">
                                <div className="flex flex-col justify-center">
                                    <span className="text-[12px] font-medium text-white leading-[14px]">
                                        GET IT ON
                                    </span>
                                    <span className="text-[20px] font-medium text-white leading-[23px]">
                                        Google Play
                                    </span>
                                </div>
                                <img
                                    className="w-[32px] h-[32px] mr-2"
                                    src={images.googlePlayIconUrl}
                                    alt="Google Play icon"
                                />
                            </div>
                        </a>

                        <a href='https://apps.apple.com/in/app/finurja-banks-fds-nps-epf-mf/id6736566130' target="_blank" rel="noopener noreferrer" onClick={() => TrackEvent('DownloadAppClickediOS')}>
                            <div className="inline-flex justify-between items-center space-x-4  py-2 px-4 bg-[#2B4CBA] rounded-[8px] mt-[5%] border-[#213A8F] border-[1.76px]">
                                <div className="flex flex-col justify-center">
                                    <span className="text-[12px] font-medium text-white leading-[14px]">
                                        GET IT ON
                                    </span>
                                    <span className="text-[20px] font-medium text-white leading-[23px]">
                                        App Store
                                    </span>
                                </div>
                                <img
                                    className="h-[32px] w-auto mr-2"
                                    src={images.appStoreIconUrl}
                                    alt="Google Play icon"
                                />
                            </div>
                        </a>
                    </div>



                </div>

                {/* Asset Image */}
                <div className="absolute bottom-[-5%] w-[45%] right-[5%]">
                    <img
                        className="object-contain"
                        src={images.introAssets}
                        alt="Descriptive alt text"
                    />
                </div>
            </div>

            {/* Mobile Version */}
            <div
                className="xl:hidden relative w-full aspect-[2/3] bg-no-repeat bg-center bg-cover overflow-visible mt-[15%]"
                style={{ backgroundImage: `url(${images.introBgMobile})` }}
            >
                {/* Text Section */}
                <div className="absolute px-[5%] text-left">
                    <h1 className="font-lora font-bold text-[18px] md:text-[30px] lg:text-[40px] text-[#0E193E]">
                        Tools and co-pilot for preserving
                        <br />
                        <span className="text-[#0E193E]"> and growing your </span>
                        <span className="text-[#4565D4] italic">money!</span>
                    </h1>

                    <div className='flex flex-row space-x-3 mt-2'>
                        <a href='https://play.google.com/store/apps/details?id=com.finurja.finurja_mobile&hl=en_IN' target="_blank" rel="noopener noreferrer" onClick={() => TrackEvent('DownloadAppClicked')}>
                            <div className="inline-flex justify-between items-center space-x-2  py-1 md:py-2 px-2 md:px-4 bg-[#2B4CBA] rounded-[8px] mt-2 border-[#213A8F] border-[1px]">
                                <div className="flex flex-col justify-center">
                                    <span className="text-[8px] md:text-[10px] lg:text-[12px] font-medium text-white leading-[10px] md:leading-[14px]">
                                        GET IT ON
                                    </span>
                                    <span className="text-[12px] md:text-[16px] lg:text-[20px] font-medium text-white leading-[14px] md:leading-[23px]">
                                        Google Play
                                    </span>
                                </div>
                                <img
                                    className="h-[22px] md:h-[32px] w-auto mr-2"
                                    src={images.googlePlayIconUrl}
                                    alt="Google Play icon"
                                />
                            </div>
                        </a>

                        <a href='https://apps.apple.com/in/app/finurja-banks-fds-nps-epf-mf/id6736566130' target="_blank" rel="noopener noreferrer" onClick={() => TrackEvent('DownloadAppClickediOS')}>
                            <div className="inline-flex justify-between items-center space-x-2 py-1 md:py-2 px-2 md:px-4 bg-[#2B4CBA] rounded-[8px] mt-2 border-[#213A8F] border-[1px]">
                                <div className="flex flex-col justify-center">
                                    <span className="text-[8px] md:text-[10px] lg:text-[12px] font-medium text-white leading-[10px] md:leading-[14px]">
                                        GET IT ON
                                    </span>
                                    <span className="text-[12px] md:text-[16px] lg:text-[20px] font-medium text-white leading-[14px] md:leading-[23px]">
                                        App Store
                                    </span>
                                </div>
                                <img
                                    className="h-[22px] md:h-[32px] w-auto mr-2"
                                    src={images.appStoreIconUrl}
                                    alt="Google Play icon"
                                />
                            </div>
                        </a>
                    </div>
                </div>

                {/* Asset Image */}
                <div className="absolute bottom-[-8%] w-full flex justify-center">
                    <img
                        className="object-contain w-[90%]"
                        src={images.introAssets}
                        alt="Descriptive alt text"
                    />
                </div>
            </div>
        </>
    );
}

export default Introduction;
